<template>
  <div class="loader"></div>
</template>

<style scoped>
.loader {
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid transparent;
  border-top-color: var(--color-main-accent);
  border-bottom-color: var(--color-main-accent);
  border-radius: 50%;
  animation: spin ease 1000ms infinite;
  transition: all 0.5s ease-in-out;
}

@keyframes spin {
  to {
    transform: rotateZ(360deg);
  }
}
</style>