<template>
  <router-view :key="$route.fullPath"></router-view>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      mobile: false,
      smallscreen: false,
    };
  },

  provide() {
    return {
      isMobile: this.isMobile,
      isSmallScreen: this.smallScreen,
    };
  },

  computed: {
    projects() {
      return this.PROJECTS();
    },

    testimonials() {
      return this.TESTIMONIALS();
    },
  },

  methods: {
    ...mapGetters(['PROJECTS', 'TESTIMONIALS']),
    ...mapActions(['GETDATA', 'UPLOAD_DATA']),

    checkScreen() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 500) {
        this.smallscreen = true;
        return;
      }
      if (windowWidth <= 1066) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
    },

    isMobile() {
      return this.mobile;
    },

    smallScreen() {
      return this.smallscreen;
    },
  },

  created() {
    this.checkScreen();
    window.addEventListener('resize', this.checkScreen);
  },

  mounted() {
    this.GETDATA();
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

:root {
  // COLOR VARIABLES
  --color-primary: #fcfcfc;
  --color-text: #030016;
  --color-badge: #2028c4;
  --color-main-accent: #05072d;
  --color-grey: #909090;
  --color-dark-grey: #707070;
  --color-grid-grey: #d7d9d7;
  --color-grid-blue-init: #005b97;
  --color-grid-blue: rgba(--color-grid-blue-init, 0.36);
  --color-cta: #fff01d;

  // FONT VARIABLES
  --font-header: 'gaveliana', sans-serif;
  --font-secondary: 'lack', sans-serif;
  --font-body: 'Quicksand', sans-serif;

  // MEDIA QUERY VARS
  --tablet-bp: 1200px;
  --mobile-bp: 600px;
}

@font-face {
  font-family: 'lack';
  src: url('./assets/fonts/Lack-Regular.woff2') format('woff2'),
    url('./assets/fonts/Lack-Regular.woff') format('woff'),
    url('./assets/fonts/Lack-Regular.eot') format('eot'),
    url('./assets/fonts/Lack-Regular.svg') format('svg'),
    url('./assets/fonts/Lack-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gaveliana';
  src: url('./assets/fonts/GavelianaRegular.woff2') format('woff2'),
    url('./assets/fonts/GavelianaRegular.woff') format('woff'),
    url('./assets/fonts/GavelianaRegular.eot') format('eot'),
    url('./assets/fonts/GavelianaRegular.svg') format('svg'),
    url('./assets/fonts/GavelianaRegular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// media queries
@media screen and (max-width: 1200px) {
  html {
    font-size: 53.6% !important;
  }

  .scalables {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  html {
    font-size: 45% !important;
  }

  .scalables {
    width: 80%;
  }

  .nav-logo {
    top: 0 !important;
    transform: translateX(-50%) scale(0.8) !important;
  }
}

@media screen and (max-width: 500px) {
  html {
    font-size: 37.5% !important;
  }

  .scalables {
    width: 70%;
  }

  .nav-logo {
    transform: translateX(-50%) scale(0.6) !important;
  }

  .foot-logo {
    transform: scale(0.7);
  }
}

// Normal styles

::-webkit-scrollbar {
  width: 0.4rem;
}
::-webkit-scrollbar:hover {
  width: 0.8rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1d0d0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-main-accent);
  border-radius: 3rem;
}

::-webkit-scrollbar-thumb {
  background: var(--color-main-accent);
  border-radius: 3rem;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: var(--color-text);
  font-family: 'Quicksand';
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .hero {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    margin-bottom: 5rem;
    object-fit: cover;

    @media screen and (max-width: 600px) {
      height: 92vh;
    }
  }

  .title {
    font-size: 7rem;
    font-family: var(--font-header);
    margin-bottom: 3.8rem;

    @media screen and (max-width: 1066px) {
      font-size: 5rem;
      text-align: center;
    }
  }

  .description {
    width: 82%;
    text-align: center;
    font-size: 2.2rem;
    margin: 0 auto 6rem;
  }

  .services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6rem;

    .header {
      margin-bottom: 3.2rem;

      position: relative;

      &-title {
        padding: 1.6rem 4.2rem;
        font-size: 2.4rem;
        font-family: var(--font-header);
        color: var(--color-primary);
        position: relative;
        z-index: 10;
      }

      &-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: perspective(900px) rotateY(31deg);
      }
    }

    .service {
      font-size: 2.2rem;
      font-weight: 400;

      &:not(:last-child) {
        margin-bottom: 1.1rem;
      }
    }
  }

  .btn {
    margin-bottom: 12.1rem;
    padding: 1.5rem 9rem;

    &:hover {
      // padding: 1.5rem 9.9rem;
      letter-spacing: 0.1rem;
    }
  }
}

.btn {
  // padding: 1.5rem 6rem;
  text-decoration: none;
  padding: 1.5rem 9rem;
  font-size: 1.9rem;
  font-family: var(--font-secondary);
  letter-spacing: tracking(203);
  color: var(--color-main-accent);
  border: 2px solid var(--color-main-accent);
  border-radius: 0.8rem;
  background: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &:hover {
    background: var(--color-main-accent);
    border: 2px solid transparent;
    color: var(--color-primary);
    // padding: 1.5rem 6.9rem;
    letter-spacing: 0.1rem;
  }
}

.selector {
  width: min-content;
  position: absolute;
  right: 3.7%;
  top: 50%;
  transform: translateY(-50%);

  &-btn {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 1.4rem;
    border: 1px solid var(--color-grid-grey);
    background: white;
    cursor: pointer;
    transition: all 0.5s ease;
  }

  &-btn:not(.selector-btn:last-child) {
    margin-bottom: 1rem;
  }
}

.project-image-container {
  width: 100%;
  height: 100vh;
  margin-bottom: 8.3rem;
  position: relative;

  .project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .cover-top {
    width: 100%;
    height: 50%;
    background: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0;
  }

  .cover-bottom {
    width: 100%;
    height: 50%;
    background: var(--color-primary);
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.btn-success {
  background: #25e825 !important;
  border: 2px solid #25e825 !important;

  &:hover {
    background: #25e825 !important;
    border: 2px solid #25e825 !important;
  }
}

.error {
  text-align: center;
  font-size: 1.7rem;
  font-family: var(--font-secondary);
  color: #ec2f2f;
  margin-bottom: 2rem;
}

.active {
  background: var(--color-grid-grey) !important;
  height: 2.7rem !important;
}

.smooth {
  scroll-behavior: smooth;
}

.mg-med {
  margin-bottom: 12rem !important;
}

.bazar {
  background: #262626;
}

.tcod {
  background: #734f41 !important;
}

.fly {
  background: #002553 !important;
}

.spartan {
  background: #fc171e !important;
}

.kunashe {
  background: #f8ab37 !important;
}

.cav {
  background: #286276 !important;
}

.hyphen {
  background: #00ff00 !important;
}

.guild {
  background: #b2753f !important;
}
</style>
