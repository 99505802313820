<template>
  <div class="container">
    <!-- SIDE NAVIGATION (TAB & MOBILE) -->
    <transition name="side-nav">
      <nav class="side-nav" v-show="showSideNav">
        <img
          src="@/assets/svg/close.svg"
          alt="close-icon"
          class="close-icon"
          @click="toggleSideNav"
        />

        <div class="side-nav-pages">
          <router-link :to="{ name: 'Projects' }" class="side-nav-page">
            <img
              src="@/assets/svg/grid.svg"
              alt="grid-icon"
              class="side-nav-page-icon"
            />
            <span class="side-nav-page-title">Projects</span>
          </router-link>
          <router-link :to="{ name: 'Profile' }" class="side-nav-page">
            <img
              src="@/assets/svg/user.svg"
              alt="user-icon"
              class="side-nav-page-icon"
            />
            <span class="side-nav-page-title">About Me</span>
          </router-link>
          <div @click="routeToContact" class="side-nav-page">
            <img
              src="@/assets/svg/email.svg"
              alt="email-icon"
              class="side-nav-page-icon email-icon"
            />
            <span class="side-nav-page-title">Contact</span>
          </div>
        </div>

        <div class="divider"></div>

        <router-link :to="{ name: 'Home' }">
          <img src="@/assets/svg/logo.svg" alt="logo" class="side-nav-logo" />
        </router-link>
      </nav>
    </transition>

    <!---------------------------------------------------------------------------->
    <!-- MAIN NAVIGATION (DESKTOP) -->
    <nav class="nav">
      <img
        src="@/assets/svg/menu.svg"
        alt="menu"
        class="menu"
        v-show="mobile"
        @click="toggleSideNav"
      />
      <router-link :to="{ name: 'Home' }" v-if="!mobile">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80.756"
          height="43.69"
          viewBox="0 0 80.756 43.69"
          class="logo nav-logo"
        >
          <g transform="translate(14.534)" opacity="0.26">
            <path
              d="M76.23,19.031v2.289H74.478a3.965,3.965,0,0,1-2.581-.751,3.032,3.032,0,0,1-.905-2.5v-5.22H69.83V10.635h1.161V8h2.776v2.635h2.441v2.217H73.768v5.224a.937.937,0,0,0,.26.747,1.366,1.366,0,0,0,.887.223Z"
              transform="translate(-69.83 -6.335)"
            />
            <path
              d="M121.554,20.391a3.863,3.863,0,0,1,1.457,1.33V20.056h2.758V30.75h-2.758v-1.8a4.223,4.223,0,0,1-1.457,1.428,4.032,4.032,0,0,1-2.056.52,4.8,4.8,0,0,1-2.55-.7,5.078,5.078,0,0,1-1.832-1.956,5.888,5.888,0,0,1-.681-2.854,5.828,5.828,0,0,1,.681-2.843,5,5,0,0,1,1.832-1.938,4.852,4.852,0,0,1,2.55-.7A4.34,4.34,0,0,1,121.554,20.391Zm-2.826,2.242a2.843,2.843,0,0,0-1.072,1.07,3.268,3.268,0,0,0-.416,1.688,3.372,3.372,0,0,0,.416,1.707,2.793,2.793,0,0,0,1.072,1.089,2.835,2.835,0,0,0,3.871-1.08,3.664,3.664,0,0,0,0-3.393A2.841,2.841,0,0,0,118.728,22.633Z"
              transform="translate(-105.151 -15.764)"
            />
            <path
              d="M191,6.517h-1.734v8.469H186.5V6.517H185.32V4.3H186.5V3.7A3.5,3.5,0,0,1,187.543.887,5.03,5.03,0,0,1,190.84,0V2.275a1.8,1.8,0,0,0-1.22.327,1.445,1.445,0,0,0-.356,1.1v.6H191Z"
              transform="translate(-161.283)"
            />
            <path
              d="M232.354,20.39a3.88,3.88,0,0,1,1.457,1.33V20.055h2.756V30.749h-2.756v-1.8a4.223,4.223,0,0,1-1.467,1.428,4.04,4.04,0,0,1-2.058.52,4.8,4.8,0,0,1-2.55-.7,5.093,5.093,0,0,1-1.832-1.956,5.888,5.888,0,0,1-.678-2.854,5.828,5.828,0,0,1,.679-2.843,5.012,5.012,0,0,1,1.832-1.938,4.852,4.852,0,0,1,2.55-.7A4.354,4.354,0,0,1,232.354,20.39Zm-2.826,2.242a2.862,2.862,0,0,0-1.074,1.07,3.289,3.289,0,0,0-.416,1.688,3.393,3.393,0,0,0,.416,1.707,2.808,2.808,0,0,0,2.462,1.457,2.856,2.856,0,0,0,1.407-.366,2.789,2.789,0,0,0,1.074-1.08,3.665,3.665,0,0,0,0-3.393,2.818,2.818,0,0,0-1.074-1.082,2.856,2.856,0,0,0-1.407-.366A2.82,2.82,0,0,0,229.528,22.632Z"
              transform="translate(-192.882 -15.764)"
            />
          </g>
          <g transform="translate(13.78 28.96)" opacity="0.26">
            <path
              d="M73.324,143.138a3.921,3.921,0,0,1,1.457,1.359V139.14h2.758v14.569H74.781v-1.832a4.273,4.273,0,0,1-1.457,1.457,3.954,3.954,0,0,1-2.058.531,4.727,4.727,0,0,1-2.55-.718,5.141,5.141,0,0,1-1.832-2,6.138,6.138,0,0,1-.679-2.914,6.075,6.075,0,0,1,.679-2.9,5.087,5.087,0,0,1,1.832-1.979,4.775,4.775,0,0,1,2.539-.7A4.252,4.252,0,0,1,73.324,143.138ZM70.5,145.428a2.883,2.883,0,0,0-1.074,1.093,3.418,3.418,0,0,0-.416,1.723,3.526,3.526,0,0,0,.416,1.744A2.835,2.835,0,0,0,70.5,151.1a2.77,2.77,0,0,0,1.388.375A2.81,2.81,0,0,0,74.367,150a3.832,3.832,0,0,0,0-3.465,2.82,2.82,0,0,0-3.869-1.109Z"
              transform="translate(-66.205 -139.14)"
            />
            <path
              d="M142.52,165.365H147.1v2.289H139.29v-2.289L143.9,159H139.29V156.74h7.818V159Z"
              transform="translate(-124.079 -153.077)"
            />
            <path
              d="M205.516,156.74l-3.349,10.91h-2.953l-1.873-7.326-1.89,7.326H192.5l-3.349-10.91h2.8l2.008,8.29,2.008-8.29h2.914l1.969,8.271,2.008-8.271Z"
              transform="translate(-163.561 -153.077)"
            />
            <path
              d="M285.974,156.486a3.921,3.921,0,0,1,1.457,1.359v-1.694h2.756v10.91h-2.756V165.23a4.273,4.273,0,0,1-1.467,1.457,3.955,3.955,0,0,1-2.058.531,4.723,4.723,0,0,1-2.55-.718,5.141,5.141,0,0,1-1.832-2,6.115,6.115,0,0,1-.679-2.914,6.052,6.052,0,0,1,.679-2.9,5.087,5.087,0,0,1,1.832-1.979,4.771,4.771,0,0,1,2.55-.708A4.265,4.265,0,0,1,285.974,156.486Zm-2.826,2.289a2.882,2.882,0,0,0-1.074,1.093,3.418,3.418,0,0,0-.416,1.723,3.526,3.526,0,0,0,.416,1.744,2.835,2.835,0,0,0,1.074,1.111,2.771,2.771,0,0,0,1.388.375,2.81,2.81,0,0,0,2.481-1.478,3.832,3.832,0,0,0,0-3.465,2.82,2.82,0,0,0-3.869-1.109Z"
              transform="translate(-234.587 -152.488)"
            />
          </g>
          <g>
            <path
              d="M16.828,89.619a4.7,4.7,0,0,1,1.191,3.426v6.421H15.26V93.43a2.7,2.7,0,0,0-.649-1.961,2.339,2.339,0,0,0-1.771-.658,2.377,2.377,0,0,0-1.784.679,2.693,2.693,0,0,0-.649,1.95v6.036H7.641V93.441a2.731,2.731,0,0,0-.649-1.961,2.341,2.341,0,0,0-1.773-.668,2.373,2.373,0,0,0-1.782.679,2.7,2.7,0,0,0-.66,1.961v6.036H0V88.556H2.777v1.632a3.463,3.463,0,0,1,1.386-1.326,4.223,4.223,0,0,1,2-.464,4.3,4.3,0,0,1,2.217.572A3.846,3.846,0,0,1,9.886,90.6a4.227,4.227,0,0,1,1.561-1.615,4.292,4.292,0,0,1,2.24-.591A4.191,4.191,0,0,1,16.828,89.619Z"
              transform="translate(0 -69.999)"
            />
            <path
              d="M115.728,75.614a3.8,3.8,0,0,1,1.507,1.594,5.339,5.339,0,0,1,.552,2.51v6.421h-2.756V80.1a2.726,2.726,0,0,0-.651-1.961,2.339,2.339,0,0,0-1.771-.679,2.377,2.377,0,0,0-1.784.679,2.7,2.7,0,0,0-.66,1.961v6.036H107.39V71.57h2.774v5.293a3.49,3.49,0,0,1,1.388-1.328,4.225,4.225,0,0,1,2-.462A4.321,4.321,0,0,1,115.728,75.614Z"
              transform="translate(-85.039 -56.674)"
            />
            <path
              d="M177.23,68.173a1.611,1.611,0,0,1,1.182-.493,1.694,1.694,0,1,1,0,3.388,1.611,1.611,0,0,1-1.182-.493A1.713,1.713,0,0,1,177.23,68.173Zm2.579,3.977v10.91h-2.774V72.15Z"
              transform="translate(-139.953 -53.593)"
            />
            <path
              d="M213.36,97.785h4.579v2.289H210.13V97.785l4.608-6.369H210.13V89.16h7.817v2.256Z"
              transform="translate(-166.395 -70.603)"
            />
            <path
              d="M274.768,75.7a3.8,3.8,0,0,1,1.507,1.594,5.338,5.338,0,0,1,.552,2.51v6.421h-2.756V80.191a2.726,2.726,0,0,0-.651-1.961,2.339,2.339,0,0,0-1.771-.656,2.377,2.377,0,0,0-1.784.679,2.7,2.7,0,0,0-.66,1.961v6.036H266.43V71.68H269.2v5.27a3.49,3.49,0,0,1,1.388-1.328,4.225,4.225,0,0,1,1.992-.462,4.321,4.321,0,0,1,2.183.541Z"
              transform="translate(-210.977 -56.761)"
            />
            <path
              d="M340.624,88.86a3.911,3.911,0,0,1,1.457,1.359V88.525h2.758v10.91h-2.756V97.6a4.273,4.273,0,0,1-1.467,1.457,3.954,3.954,0,0,1-2.058.531,4.722,4.722,0,0,1-2.55-.718,5.124,5.124,0,0,1-1.832-2,6.115,6.115,0,0,1-.681-2.914,6.053,6.053,0,0,1,.681-2.914,5.072,5.072,0,0,1,1.832-1.979,4.77,4.77,0,0,1,2.55-.71A4.25,4.25,0,0,1,340.624,88.86ZM337.8,91.149a2.86,2.86,0,0,0-1.078,1.089,3.405,3.405,0,0,0-.416,1.721,3.526,3.526,0,0,0,.416,1.744,2.822,2.822,0,0,0,1.074,1.111,2.764,2.764,0,0,0,1.388.375,2.806,2.806,0,0,0,1.407-.375,2.839,2.839,0,0,0,1.074-1.1,3.832,3.832,0,0,0,0-3.465,2.868,2.868,0,0,0-1.074-1.1,2.824,2.824,0,0,0-1.407-.373,2.785,2.785,0,0,0-1.384.373Z"
              transform="translate(-264.083 -69.968)"
            />
          </g>
        </svg>
      </router-link>
      <router-link :to="{ name: 'Home' }" v-else>
        <img src="@/assets/svg/logo-2.svg" alt="logo" class="logo nav-logo" />
      </router-link>
      <div class="nav-pages" v-show="!mobile">
        <router-link
          class="link"
          :to="{ name: 'Projects' }"
          data-tip="Projects"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.361"
            height="18.361"
            viewBox="0 0 18.361 18.361"
            class="icon-grid scalables"
          >
            <g
              id="Icon_feather-grid"
              data-name="Icon feather-grid"
              transform="translate(0.5 0.5)"
            >
              <path
                id="Path_394"
                data-name="Path 394"
                d="M4.5,4.5h6.751v6.751H4.5Z"
                transform="translate(-4.5 -4.5)"
                fill="none"
                stroke="#909090"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_395"
                data-name="Path 395"
                d="M21,4.5h6.751v6.751H21Z"
                transform="translate(-10.391 -4.5)"
                fill="none"
                stroke="#909090"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_396"
                data-name="Path 396"
                d="M21,21h6.751v6.751H21Z"
                transform="translate(-10.391 -10.391)"
                fill="none"
                stroke="#909090"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_397"
                data-name="Path 397"
                d="M4.5,21h6.751v6.751H4.5Z"
                transform="translate(-4.5 -10.391)"
                fill="none"
                stroke="#909090"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
        </router-link>
        <router-link :to="{ name: 'Profile' }" class="link" data-tip="About Me">
          <svg
            id="Icon_awesome-user"
            data-name="Icon awesome-user"
            xmlns="http://www.w3.org/2000/svg"
            width="14.717"
            height="17.39"
            viewBox="0 0 14.717 17.39"
            class="icon scalables"
          >
            <path
              id="Icon_awesome-user-2"
              data-name="Icon awesome-user"
              d="M7.358,8.7a4.278,4.278,0,0,0,4.2-4.348A4.278,4.278,0,0,0,7.358,0a4.278,4.278,0,0,0-4.2,4.348A4.278,4.278,0,0,0,7.358,8.7ZM10.3,9.782H9.753a5.548,5.548,0,0,1-4.79,0H4.415A4.493,4.493,0,0,0,0,14.347V15.76a1.6,1.6,0,0,0,1.577,1.63H13.14a1.6,1.6,0,0,0,1.577-1.63V14.347A4.493,4.493,0,0,0,10.3,9.782Z"
              fill="#909090"
            />
          </svg>
        </router-link>
        <a
          href="#contact"
          v-if="this.$route.path === '/'"
          id="contactLink"
          class="link"
          data-tip="Contact"
        >
          <svg
            id="Icon_zocial-email"
            data-name="Icon zocial-email"
            xmlns="http://www.w3.org/2000/svg"
            width="15.124"
            height="10.584"
            viewBox="0 0 15.124 10.584"
            class="email-icon scalables"
          >
            <path
              id="Icon_zocial-email-2"
              data-name="Icon zocial-email"
              d="M.072,13.715V5.005q0-.015.044-.287L5.06,9.1.13,14.017a1.323,1.323,0,0,1-.058-.3Zm.656-9.6a.609.609,0,0,1,.248-.045H14.292a.8.8,0,0,1,.263.045L9.6,8.513l-.656.544-1.3,1.1-1.3-1.1-.656-.544ZM.743,14.607,5.716,9.662,7.641,11.28,9.567,9.662l4.973,4.944a.678.678,0,0,1-.248.045H.976a.64.64,0,0,1-.233-.045Zm9.48-5.5,4.93-4.385a.933.933,0,0,1,.044.287v8.709a1.2,1.2,0,0,1-.044.3Z"
              transform="translate(-0.072 -4.068)"
              fill="#909090"
            />
          </svg>
        </a>
        <div @click="routeToContact" class="link" data-tip="Contact" v-else>
          <svg
            id="Icon_zocial-email"
            data-name="Icon zocial-email"
            xmlns="http://www.w3.org/2000/svg"
            width="15.124"
            height="10.584"
            viewBox="0 0 15.124 10.584"
            class="email-icon scalables"
          >
            <path
              id="Icon_zocial-email-2"
              data-name="Icon zocial-email"
              d="M.072,13.715V5.005q0-.015.044-.287L5.06,9.1.13,14.017a1.323,1.323,0,0,1-.058-.3Zm.656-9.6a.609.609,0,0,1,.248-.045H14.292a.8.8,0,0,1,.263.045L9.6,8.513l-.656.544-1.3,1.1-1.3-1.1-.656-.544ZM.743,14.607,5.716,9.662,7.641,11.28,9.567,9.662l4.973,4.944a.678.678,0,0,1-.248.045H.976a.64.64,0,0,1-.233-.045Zm9.48-5.5,4.93-4.385a.933.933,0,0,1,.044.287v8.709a1.2,1.2,0,0,1-.044.3Z"
              transform="translate(-0.072 -4.068)"
              fill="#909090"
            />
          </svg>
        </div>
      </div>
    </nav>
    <slot></slot>
    <footer class="footer">
      <div class="footer-nav-panel">
        <router-link :to="{ name: 'Projects' }" class="footer-nav-panel-link"
          >Projects</router-link
        >
        <router-link :to="{ name: 'Profile' }" class="footer-nav-panel-link"
          >About Me</router-link
        >
        <a
          href="#contact"
          v-if="this.$route.path === '/'"
          class="footer-nav-panel-link"
        >
          Contact
        </a>
        <div v-else @click="routeToContact" class="footer-nav-panel-link">
          Contact
        </div>
      </div>
      <router-link :to="{ name: 'Home' }" class="footer-logo" v-show="!mobile">
        <img
          src="@/assets/svg/logo-light.svg"
          alt="logo-light"
          class="scalables"
        />
      </router-link>
      <div class="footer-contact">
        <div class="footer-contact-links">
          <a :href="instagram" target="_blank" class="link">
            <svg
              id="Icon_ionic-logo-instagram"
              data-name="Icon ionic-logo-instagram"
              xmlns="http://www.w3.org/2000/svg"
              width="17.862"
              height="17.862"
              viewBox="0 0 17.862 17.862"
              class="scalables"
            >
              <path
                id="Path_391"
                data-name="Path 391"
                d="M17.152,5.989A3.732,3.732,0,0,1,20.874,9.71v7.443a3.732,3.732,0,0,1-3.721,3.721H9.71a3.732,3.732,0,0,1-3.721-3.721V9.71A3.732,3.732,0,0,1,9.71,5.989h7.443m0-1.489H9.71A5.225,5.225,0,0,0,4.5,9.71v7.443a5.225,5.225,0,0,0,5.21,5.21h7.443a5.225,5.225,0,0,0,5.21-5.21V9.71a5.225,5.225,0,0,0-5.21-5.21Z"
                transform="translate(-4.5 -4.5)"
                fill="#fcfcfc"
              />
              <path
                id="Path_392"
                data-name="Path 392"
                d="M24.741,11.233a1.116,1.116,0,1,1,1.116-1.116A1.114,1.114,0,0,1,24.741,11.233Z"
                transform="translate(-10.973 -6.023)"
                fill="#fcfcfc"
              />
              <path
                id="Path_393"
                data-name="Path 393"
                d="M15.716,12.739a2.977,2.977,0,1,1-2.977,2.977,2.98,2.98,0,0,1,2.977-2.977m0-1.489a4.466,4.466,0,1,0,4.466,4.466,4.467,4.467,0,0,0-4.466-4.466Z"
                transform="translate(-6.784 -6.784)"
                fill="#fcfcfc"
              /></svg
          ></a>
          <a :href="facebook" target="_blank" class="link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.98"
              height="15.98"
              viewBox="0 0 15.98 15.98"
              class="scalables"
            >
              <path
                id="Icon_ionic-logo-facebook"
                data-name="Icon ionic-logo-facebook"
                d="M19.6,4.5H5.382a.883.883,0,0,0-.882.882V19.6a.883.883,0,0,0,.882.882H12.49V14.154H10.58v-2.33h1.91V10.1a3.036,3.036,0,0,1,3.275-3.188c.882,0,1.831.067,2.052.1V9.165H16.347c-1,0-1.194.474-1.194,1.174v1.486h2.389l-.312,2.33H15.153V20.48H19.6a.883.883,0,0,0,.882-.882V5.382A.883.883,0,0,0,19.6,4.5Z"
                transform="translate(-4.5 -4.5)"
                fill="#fcfcfc"
              /></svg
          ></a>
        </div>
      </div>
      <router-link :to="{ name: 'Home' }" class="footer-logo" v-show="mobile">
        <img
          src="@/assets/svg/logo-light.svg"
          alt="logo-light"
          class="foot-logo"
        />
      </router-link>
      <p class="copy">tafadzwa-mhizha @{{ new Date().getFullYear() }}</p>
    </footer>
  </div>
</template>


<script>
export default {
  inject: ['isMobile', 'isSmallScreen'],

  data() {
    return {
      facebook: 'https://www.facebook.com/profile.php?id=100084547599477',
      instagram: 'https://www.instagram.com/tafadzwamhizha_/',
      showSideNav: false,
    };
  },

  computed: {
    mobile() {
      return this.isMobile() || this.isSmallScreen();
    },
  },

  methods: {
    routeToContact() {
      this.$store.commit('SET_ROUTE_TO_CONTACT', true);
      this.$router.push({ name: 'Home' });
    },

    toggleSideNav() {
      this.showSideNav = !this.showSideNav;
    },
  },
};
</script>

<style lang="scss" scoped>
@function tracking($size) {
  @return calc(1em * 0.001 * $size);
}

.container {
  background: var(--color-primary);
  position: relative;

  .side-nav {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--color-primary);
    display: flex;
    flex-direction: column;
    // align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 12rem 7rem;
    z-index: 1000;

    &-logo {
      // display: block;
      // align-self: center;
      width: 12rem;
      // margin: auto auto;
      position: absolute;
      bottom: 8%;
      left: 50%;
      transform: translateX(-50%);
    }

    .close-icon {
      position: absolute;
      top: 4rem;
      right: 5rem;
      width: 2rem;
    }

    &-pages {
      display: flex;
      flex-direction: column;
      gap: 7rem;

      .side-nav-page {
        display: flex;
        align-items: center;
        gap: 4.8rem;
        text-decoration: none;

        &-title {
          font-size: 3rem;
          font-family: var(--font-header);
          letter-spacing: tracking(40);
        }

        &-icon {
          width: 3rem;
        }
      }
    }

    .divider {
      height: 1px;
      width: 70%;
      background: var(--color-grid-grey);
      margin: 19rem auto;
      // margin: 4.95rem 0 4.55rem 0;
    }
  }

  .nav {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.8rem 6rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    @media screen and (max-width: 1066px) {
      padding: 1.8rem 3rem;
    }

    .menu {
      width: 3rem;
    }

    .logo {
      position: absolute;
      left: 50%;
      top: 1rem;
      transform: translateX(-50%) scale(0.94);
      margin-left: auto;
      cursor: pointer;
      transition: all 0.3s ease;

      @media screen and (max-width: 600px) {
        transform: translateX(-50%) scale(0.98);
      }

      &:hover {
        transform: translateX(-50%) scale(0.96);

        g {
          fill: #005b97;
        }
      }
    }

    &-pages {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 5rem;

      .link {
        position: relative;
      }

      .link::after {
        position: absolute;
        bottom: -4.5rem;
        left: -5rem;
        content: attr(data-tip);
        background: #202842;
        color: var(--color-primary);
        padding: 1rem 3rem;
        font-family: var(--font-secondary);
        font-size: 1.4rem;
        border-radius: 0.3rem;
        margin: auto;
        width: max-content;
      }

      #contactLink::after {
        bottom: -5.2rem;
      }

      #contactLink::before {
        @media screen and (max-width: 1200px) {
          top: 2rem;
        }
      }

      .link::before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 1rem solid #202842;
        margin: auto;
        left: 0;
        top: 2rem;

        @media screen and (max-width: 1200px) {
          top: 2.5rem;
        }
      }

      .link::after,
      .link::before {
        display: none;
      }

      .link:hover::after,
      .link:hover::before {
        display: block;
      }

      .icon-grid {
        cursor: pointer;
        transition: all 0.3s ease-in;

        &:hover {
          transform: scale(1.1);

          path {
            stroke: #005b97;
          }
        }
      }

      .email-icon {
        cursor: pointer;
        transition: all 0.3s ease-in;
        transform: scale(1.3);

        &:hover {
          transform: scale(1.4);

          path {
            fill: #005b97;
          }
        }
      }

      .icon {
        cursor: pointer;
        transition: all 0.3s ease-in;

        &:hover {
          transform: scale(1.1);

          path {
            fill: #005b97;
          }
        }
      }
    }
  }

  .footer {
    scroll-snap-align: start;
    background: var(--color-main-accent);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 7.2rem 10rem 7.2rem 10rem;
    // padding: 7.2rem;
    margin-top: auto;
    position: relative;

    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      padding: 7.2rem 0;
    }

    &-logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: 500px) {
        position: initial;
        left: 0;
        transform: translateX(0);
        width: max-content;
        align-self: center;
      }
    }

    &-nav-panel {
      display: flex;
      flex-direction: column;
      gap: 3.4rem;

      @media screen and (max-width: 500px) {
        flex-direction: row;
        width: 70%;
        justify-content: space-between;
        margin-bottom: 5rem;
      }

      &-link {
        text-decoration: none;
        color: var(--color-primary);
        font-size: 1.6rem;
        cursor: pointer;
      }
    }

    &-contact {
      @media screen and (max-width: 500px) {
        margin-bottom: 2rem;
      }

      &-links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.1rem;

        @media screen and (max-width: 500px) {
          gap: 3rem;
        }

        .link,
        .link:active,
        .link:visited {
          padding: 0.9rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          transition: all 0.4s ease;

          @media screen and (max-width: 500px) {
            transform: scale(1.25);
          }

          &:hover {
            background: var(--color-primary);

            svg {
              path {
                fill: var(--color-main-accent);
              }
            }
          }
        }
      }

      // .copywrite {
      //   margin-top: 1.4rem;
      //   color: var(--color-primary);
      //   font-size: 1.4rem;
      // }
    }

    .copy {
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.1rem;
      color: var(--color-primary);
    }
  }
}

.side-nav-enter-from,
.side-nav-leave-to {
  transform: translateX(-100%);
}

.side-nav-enter-to,
.side-nav-leave-from {
  transform: translateX(0);
}

.side-nav-enter-active,
.side-nav-leave-active {
  transition: all 0.3s ease;
}
</style>