<template>
  <div class="project">
    <div class="project-title-container">
      <h3 class="project-title">{{ project.title }}</h3>
    </div>
    <div class="project-content">
      <img :src="coverImage" alt="project" class="cover" loading="lazy" />
      <div class="description">
        <p class="description-text">{{ project.description }}</p>
        <div class="description-link">
          <router-link :to="projectRoute()" class="description-link-btn"
            >Take a peek</router-link
          >
          <div class="description-link-background" @click="route"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['project'],

  data() {
    return {
      mobile: false,
      skinnyMobile: false,
    };
  },

  computed: {
    coverImage() {
      if (this.mobile) {
        return this.project.cover.mobile.post;
      }
      if (this.skinnyMobile) {
        return this.project.cover.mobile.story;
      }
      return this.project.cover.desktop;
    },
  },

  methods: {
    route() {
      if (this.project.routeComponent === 'logo') {
        this.$router.push({
          name: 'LogoDesign',
          params: { projectId: this.project.name },
        });
      } else if (this.project.routeComponent === 'brand') {
        this.$router.push({
          name: 'BrandIdentity',
          params: { projectId: this.project.name },
        });
      } else {
        this.$router.push({
          name: 'Project',
          params: { projectId: this.project.name },
        });
      }
    },

    projectRoute() {
      if (this.project.routeComponent === 'logo') {
        return { name: 'LogoDesign', params: { projectId: this.project.name } };
      } else if (this.project.routeComponent === 'brand') {
        return {
          name: 'BrandIdentity',
          params: { projectId: this.project.name },
        };
      }
      return { name: 'Project', params: { projectId: this.project.name } };
    },

    checkScreen() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 390) {
        this.mobile = false;
        this.skinnyMobile = true;
        return;
      }
      if (windowWidth <= 426) {
        this.mobile = true;
        this.skinnyMobile = false;
        return;
      }
      this.mobile = false;
      this.skinnyMobile = false;
    },
  },

  created() {
    this.checkScreen();
    window.addEventListener('resize', this.checkScreen);
  },
};
</script>

<style lang="scss">
.project {
  background: white;
  height: 50%;
  padding: 7.8rem 11.2rem 7.8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    padding: 0 5rem 7.8rem 5rem !important;
    height: 20%;
  }

  &-title-container {
    width: 9%;
    height: 100%;
    position: relative;

    @media screen and (max-width: 500px) {
      width: 100%;
      height: 7.8rem;
    }

    .project-title {
      position: absolute;
      font-size: 1.9rem;
      font-family: var(--font-secondary);
      font-weight: 400;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      width: max-content;

      @media screen and (max-width: 500px) {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }

  &-content {
    flex: 1;
    height: 100%;
    position: relative;

    @media screen and (max-width: 400px) {
      height: 50%;
      width: 100%;
    }

    &:hover {
      .description {
        visibility: visible;
      }
      .cover {
        filter: blur(2px) brightness(60%);
      }
    }

    .cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
      transition: all 0.2s ease;
    }

    .description {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      visibility: hidden;
      transition: all 0.1s ease;

      &-text {
        font-size: 1.9rem;
        text-align: center;
        color: var(--color-primary);
        cursor: default;
      }

      &-link {
        // padding: 1.6rem 1.9rem 1.4rem 1.9rem;
        padding: 1.6rem 4rem 1.4rem 4rem;
        border-bottom: 1px solid var(--color-primary);
        cursor: pointer;
        position: relative;

        @media screen and (max-width: 600px) {
          margin-top: 2rem;
        }

        &:hover {
          .description-link-background {
            height: 100%;
          }

          .description-link-btn {
            color: var(--color-text);
          }

          transform: translateY(-0.1rem);
        }

        &-btn {
          text-decoration: none;
          // font-family: var(--font-secondary);
          font-weight: bold;
          font-size: 1.6rem;
          color: var(--color-primary);
          transition: color 0.4s ease;

          @media screen and (max-width: 600px) {
            color: var(--color-text);
          }
        }

        &-background {
          background: var(--color-primary);
          position: absolute;
          bottom: 0;
          left: 0;
          height: 0;
          width: 100%;
          z-index: -1;
          transition: height 0.3s ease;

          @media screen and (max-width: 600px) {
            height: 100%;
          }
        }
      }
    }
  }
}
</style>