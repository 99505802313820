import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PageContainer from './components/PageContainer';
import ProjectCard from './components/ProjectCard';
// import TiltedCard from './components/TiltedCard';
import ScrollToTop from './components/ScrollToTop';

const app = createApp(App);
app.use(store);
app.use(router);
app.component('page-container', PageContainer);
app.component('project-card', ProjectCard);
// app.component('tilted-card', TiltedCard);
app.component('scroll-top', ScrollToTop);

app.mount('#app');
