import { createStore } from 'vuex';
import { db } from '../firebase/init.js';
import {
  collection,
  getDocs,
  orderBy,
  query,
  // addDoc,
  // serverTimestamp,
} from 'firebase/firestore';

export default createStore({
  state: {
    projects: [],
    testimonials: [],
    routeToContact: false,
    autoShowModal: true,
  },

  getters: {
    PROJECTS(state) {
      return state.projects;
    },

    TESTIMONIALS(state) {
      return state.testimonials;
    },

    ROUTE_TO_CONTACT(state) {
      return state.routeToContact;
    },

    AUTO_SHOW_MODAL(state) {
      return state.autoShowModal;
    },
  },

  mutations: {
    SET_ROUTE_TO_CONTACT(state, payload) {
      state.routeToContact = payload;
    },

    SET_DATA(state, payload) {
      state.projects = payload.projects;
      state.testimonials = payload.testimonials;
    },

    RESET_SHOW_MODAL(state) {
      state.autoShowModal = false;
    },
  },

  actions: {
    async GETDATA({ commit }) {
      const projectsColRef = collection(db, 'projects');
      const testimonialsColRef = collection(db, 'testimonials');
      const projectQuery = query(projectsColRef, orderBy('createdAt', 'desc'));
      const testimonialQuery = query(
        testimonialsColRef,
        orderBy('createdAt', 'desc')
      );
      const projectSnap = await getDocs(projectQuery);
      const testimonialSnap = await getDocs(testimonialQuery);

      let projects = [];
      let testimonials = [];

      projectSnap.docs.forEach((doc) => {
        projects.push({ ...doc.data() });
      });

      testimonialSnap.docs.forEach((doc) => {
        testimonials.push({ ...doc.data() });
      });

      const data = { projects, testimonials };

      commit('SET_DATA', data);
    },

    // UPLOAD_DATA({ state }) {
    //   // const projectsColRef = collection(db, 'projects');
    //   const testimonialsColRef = collection(db, 'testimonials');

    //   // console.log(this.state.projects);

    //   state.projects.forEach((project) => {
    //     addDoc(projectsColRef, {
    //       ...project,
    //       createdAt: serverTimestamp(),
    //     });
    //   });

    //   state.testimonials.forEach((testimonial) => {
    //     addDoc(testimonialsColRef, {
    //       ...testimonial,
    //       createdAt: serverTimestamp(),
    //     });
    //   });
    // },
  },
});
