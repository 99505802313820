<template>
  <div class="container">
    <div class="modal">
      <img
        src="@/assets/svg/close.svg"
        alt="close-icon"
        class="close-svg"
        @click="closeModal"
      />
      <h2 class="modal-title">Free Website Analysis Report</h2>
      <p class="modal-description">
        Improve your website's performance, SEO ranking, usability, conversion
        rates and more with a FREE Website Analysis Report. To get one, enter
        your email address and website url - it's that simple.
      </p>
      <form @submit.prevent="signUp" class="modal-form">
        <div class="form-control">
          <label for="email" class="form-control-label">Email</label>
          <input
            type="email"
            class="email-input form-control-input"
            name="email"
            v-model="email"
            ref="email"
            required
          />
        </div>
        <div class="form-control">
          <label for="url" class="form-control-label"
            >Website Address (URL)</label
          >
          <input
            type="text"
            class="url-input form-control-input"
            name="url"
            v-model="url"
            ref="url"
            required
          />
        </div>
        <p v-show="error" class="error">{{ errorMsg }}</p>
        <button
          class="modal-form-btn"
          :class="{ 'btn-success': success }"
          ref="contactBtn"
        >
          <img
            src="@/assets/svg/check.svg"
            alt="check-svg"
            class="modal-form-btn-icon"
            v-show="success"
          />
          <span v-show="!isLoading & !success">Sign Up</span>

          <loading-spinner v-show="isLoading & !success"></loading-spinner>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  components: {
    LoadingSpinner,
  },

  data() {
    return {
      url: null,
      email: null,
      isLoading: false,
      success: false,
      errorMsg:
        'Sorry we were not able to send your message. Please try again or check your internet connection.',
      error: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit('close-modal');
    },

    signUp() {
      this.isLoading = true;
      this.$refs.email.disabled = true;
      this.$refs.url.disabled = true;

      emailjs
        .send('service_btpjmdx', 'template_kmes304', {
          message: `Website Analysis Report requested for : ${this.url}`,
          reply_to: this.email,
        })
        .then(() => {
          this.isLoading = false;
          this.success = true;
          setTimeout(() => {
            this.$emit('close-modal');
          }, 2000);
        })
        .catch(() => {
          this.isLoading = false;
          this.error = true;
          this.$refs.email.disabled = false;
          this.$refs.url.disabled = false;
          setTimeout(() => {
            this.error = false;
          }, 3000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@function tracking($size) {
  @return calc(1em * 0.001 * $size);
}

.container {
  backdrop-filter: blur(1px);
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;

  .close-svg {
    position: absolute;
    top: 2rem;
    right: 3rem;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 50%;
    padding: 1rem;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(0.8);
      border-color: black;
    }

    @media screen and (max-width: 700px) {
      transform: scale(0.6);
      top: 1rem;
      right: 1rem;
      border-color: black;
    }
  }

  .modal {
    background: var(--color-primary);
    padding: 3rem 0 5rem;
    width: 70%;
    border-radius: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media screen and (max-width: 640px) {
      width: 80%;
    }

    &-title {
      font-family: var(--font-header);
      font-size: 4rem;
      margin-bottom: 2rem;

      @media screen and (max-width: 640px) {
        width: 80%;
        text-align: center;
      }
    }

    &-description {
      font-size: 1.8rem;
      width: 70%;
      text-align: center;
      margin-bottom: 2rem;
    }

    &-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-control {
        display: flex;
        flex-direction: column;
        gap: 1.4rem;
        margin-bottom: 3.3rem;

        &-label {
          font-size: 1.8rem;
          font-family: var(--font-secondary);
        }

        &-input {
          border-radius: 0.7rem;
          border: none;
          background-color: #2028c40e;
          font-size: 1.8rem;
          padding: 1.5rem;
          width: 50rem;

          @media screen and (max-width: 640px) {
            width: 35rem;
          }

          @media screen and (max-width: 298px) {
            width: 30rem;
          }

          &:focus {
            outline: 2px solid var(--color-main-accent);
          }
        }
      }

      &-btn {
        // padding: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 6rem;
        width: 35%;
        border: none;
        border-radius: 0.8rem;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
        background-color: var(--color-cta);
        cursor: pointer;
        z-index: 100;
        transition: all 0.3s ease-in-out;

        &:active {
          transform: translateY(0px) scale(1);
        }

        span {
          font-size: 1.7rem;
          font-family: var(--font-secondary);
          letter-spacing: tracking(203);
          transition: all 0.5s ease-in-out;
        }

        &-icon {
          width: 2.5rem;
        }

        &:hover {
          span {
            letter-spacing: 0.1rem;
          }
        }
      }
    }
  }
}

.error {
  width: 70%;
}
</style>