// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyB-XYwRF3Dz1oo35W6_glXqB_O_oV1tOfQ',
  authDomain: 'tafadzwa-mhizha.firebaseapp.com',
  projectId: 'tafadzwa-mhizha',
  storageBucket: 'tafadzwa-mhizha.appspot.com',
  messagingSenderId: '21536207545',
  appId: '1:21536207545:web:730bbc3db5f1f6583a2168',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const db = getFirestore();

export { db, app };

// import { getAnalytics } from 'firebase/analytics';
// const analytics = getAnalytics(app);
