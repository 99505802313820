<template>
  <div class="top" @click="scrollToTop">
    <img src="@/assets/svg/chevron.svg" alt="go-to-top" />
  </div>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      document.querySelector('html').classList.add('smooth');
      setTimeout(() => window.scrollTo(0, 0), 100);
      setTimeout(
        () => document.querySelector('html').classList.remove('smooth'),
        300
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.16);
  position: absolute;
  bottom: 7.5rem;
  right: 6.1rem;
  cursor: pointer;
  transition: all 0.5s ease;

  @media screen and (max-width: 600px) {
    right: 3rem;
    bottom: 3.2rem;
  }

  img {
    transform: rotate(180deg);
    width: 2.3rem;
  }

  &:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
  }
}
</style>