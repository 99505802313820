// import { defineAsyncComponent } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
const Projects = () => import('../views/Projects.vue');
const Project = () => import('../views/Project.vue');
const LogoDesignView = () => import('../views/LogoDesignView.vue');
const BrandIdentity = () => import('../views/BrandIdentity.vue');
const Profile = () => import('../views/Profile.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home',
      fromContact: false,
    },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      title: 'Projects',
    },
  },
  {
    path: '/project/:projectId',
    name: 'Project',
    component: Project,
    props: true,
    meta: {
      title: 'Web Design Eswatini',
    },
  },
  {
    path: '/logo-design/:projectId',
    name: 'LogoDesign',
    component: LogoDesignView,
    props: true,
    meta: {
      title: 'Logo Design Eswatini',
    },
  },
  {
    path: '/brand-design/:projectId',
    name: 'BrandIdentity',
    component: BrandIdentity,
    props: true,
    meta: {
      title: 'Brand Identity Design Eswatini',
    },
  },
  {
    path: '/about-me',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Web Designer Eswatini',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_, _2, savedPosition) {
    /*  scrollBehavior(to, from, savedPosition) called by router whenever route changes to control
	  the scroll behavior. Should return an object with top and left vals that refer to scroll
	  position */
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach((to, _2, next) => {
  document.title = `${to.meta.title} | tafadzwamhizha`;
  next();
});

export default router;
